import React from "react";

export const Gallery = () => {
  return (
    <div id="portfolio">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">
            <div className="portfolio-card">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed arcu enim, placerat sit amet iaculis sit amet, vulputate quis diam. Vestibulum ut sodales nibh. Mauris lacinia lacinia pretium. Vestibulum lacinia euismod nulla, aliquam sagittis magna lobortis vel.
              </p>

              <div className="portfolio-icons">
                <i className="fa fa-facebook"></i>
                <i className="fa fa-twitter"></i>
                <i className="fa fa-linkedin"></i>
                <i className="fa fa-youtube"></i>
              </div>
            </div>
            <div className="portfolio-subtitle">
              Lorem Ipsum
            </div>
            <div className="portfolio-subtext">
              İŞ HUKUKU
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="portfolio-card">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed arcu enim, placerat sit amet iaculis sit amet, vulputate quis diam. Vestibulum ut sodales nibh. Mauris lacinia lacinia pretium. Vestibulum lacinia euismod nulla, aliquam sagittis magna lobortis vel.
              </p>

              <div className="portfolio-icons">
                <i className="fa fa-facebook"></i>
                <i className="fa fa-twitter"></i>
                <i className="fa fa-linkedin"></i>
                <i className="fa fa-youtube"></i>
              </div>
            </div>
            <div className="portfolio-subtitle">
              Lorem Ipsum
            </div>
            <div className="portfolio-subtext">
              İŞ HUKUKU
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="portfolio-card">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed arcu enim, placerat sit amet iaculis sit amet, vulputate quis diam. Vestibulum ut sodales nibh. Mauris lacinia lacinia pretium. Vestibulum lacinia euismod nulla, aliquam sagittis magna lobortis vel.
              </p>

              <div className="portfolio-icons">
                <i className="fa fa-facebook"></i>
                <i className="fa fa-twitter"></i>
                <i className="fa fa-linkedin"></i>
                <i className="fa fa-youtube"></i>
              </div>
            </div>
            <div className="portfolio-subtitle">
              Lorem Ipsum
            </div>
            <div className="portfolio-subtext">
              İŞ HUKUKU
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="portfolio-card">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed arcu enim, placerat sit amet iaculis sit amet, vulputate quis diam. Vestibulum ut sodales nibh. Mauris lacinia lacinia pretium. Vestibulum lacinia euismod nulla, aliquam sagittis magna lobortis vel.
              </p>

              <div className="portfolio-icons">
                <i className="fa fa-facebook"></i>
                <i className="fa fa-twitter"></i>
                <i className="fa fa-linkedin"></i>
                <i className="fa fa-youtube"></i>
              </div>
            </div>
            <div className="portfolio-subtitle">
              Lorem Ipsum
            </div>
            <div className="portfolio-subtext">
              İŞ HUKUKU
            </div>
          </div> 
        </div>
      </div>
    </div>
  );
};
