import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Contact } from "./components/contact";
import "./App.css";



const App = () => {
  

  return (
    <div>
      <Navigation />
      <Header/>
      <Features/>
      <About/>
      <Services/>
      <Gallery/>
      <Contact/>
    </div>
  );
};

export default App;
