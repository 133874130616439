import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";

export const About = () => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <h3>İlke Hukuk</h3>
            <p>
              İlke Hukuk İlke Hukuk & Danışmanlık, 2007 yılında Ankara’ da kurulmuştur. Eğitim, Sanayi, Ticaret, Telekom, bilişim teknolojileri, başta olmak üzere birçok sektörde hukuki danışmanlık hizmeti vermiştir. Ankara’nın önde gelen şirketlerinin hukuki danışmanlığını yapmış, dava ve icra dosyalarını takip etmiştir. Hizmet verdiği tüm şirket ve şahıslarla uzun süreli ve sağlam ilişkiler kurmuş, Güven esaslı ilişkiler tesis etmiştir.
            </p>
            <h3>Önleyici Hulkuk Hizmetleri</h3>
            <p>
              İlke Hukuk & Danışmanlık, yaklaşık 10 yılı aşkın süredir ekibi ile müvekkillerine daha etkin, süratli ve çözüm odaklı, önleyici hukuk hizmetleri vermeyi amaç edinmiş ve bu doğrultuda istekle çalışmaya devam etmektedir.
            </p>
            <h3>
              Uzmanlık & Güven
            </h3>
            <p>
              İlke Hukuk & Danışmanlık, müvekkilleri ile uzun soluklu, güven esaslı iş ortaklıkları kurmayı ana hedefi olarak belirlemiştir
            </p>
          </div>
          <div className="col-xs-12 col-md-6">
          <div className="progress-wrapper">
            <span className="title-progress">Justice</span>
            <ProgressBar completed={75} bgColor="#C1AA81" />
          </div>
          <div className="progress-wrapper">
            <span className="title-progress">Criminal Law</span>
            <ProgressBar completed={58} bgColor="#C1AA81" />
          </div>
          <div className="progress-wrapper">
            <span className="title-progress">Legislation</span>
            <ProgressBar completed={63} bgColor="#C1AA81" />
          </div>
          <div className="progress-wrapper">
            <span className="title-progress">Independent judge</span>
            <ProgressBar completed={32} bgColor="#C1AA81" />
          </div>
          <div className="progress-wrapper">
            <span className="title-progress">Judjes Expirience</span>
            <ProgressBar completed={91} bgColor="#C1AA81" />
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};
