import React from "react";

export const Navigation = () => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
      <div className="header-contact">
        <div><i className="fa fa-phone"></i><a href="tel:+90533 123 45 67">533 123 45 67</a></div>
        <div className="header-mail"><a href="mailto:info@aryahukuk.com">info@aryahukuk.com</a></div>
      </div>
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a href="#header">
          <img href="#header" className="navbar-brand page-scroll" src="../img/logo.png" alt="header-logo"/>
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#about" className="page-scroll header-item">
                Hakkımızda
              </a>
            </li>
            <li>
              <a href="#features" className="page-scroll header-item">
                Faaliyet Alanlarımız
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll header-item">
                İletişim
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll header-item header-button">
                <div>TAZMİNAT  HESAPLA</div>
              </a>
            </li>
            {/* <li>
              <a href="#testimonials" className="page-scroll header-item">
                Testimonials
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll header-item">
                Team
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll header-item">
                Contact
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};
