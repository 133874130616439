import React from "react";

export const Services = () => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>TAZMİNAT HESAAPLA</h2>
          <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis non neque elementum, imperdiet dolor vel, egestas quam. Fusce nisl odio, elementum nec fermentum eget, laoreet id purus. Nulla volutpat fermentum sodales. Aliquam quis velit eu metus lacinia feugiat at sed felis. Sed non nibh elementum, malesuada metus in, volutpat ex.
          </p>
        </div>
        <div className="row">
        <a
                  href="#services"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  HEMEN HESAPLA
                </a>
        </div>
      </div>
    </div>
  );
};
