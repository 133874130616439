import { useState } from "react";
import React from "react";


export const Contact = (props) => {

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5 contact-info">
              <img className="contact-logo" src="../img/logo.png" alt="contact-logo" />
              <div className="row">
                <div className="col-12 col-lg-6">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit 9/6
                  </p>
                </div>
                <div className="col-12 col-lg-6">
                  <p>
                    Tel: 0385 65 98 16
                  </p>
                  <p>
                    Mobile: +3856 459 37 98
                  </p>
                  <p>
                    E-mail: hello@inch.com
                  </p>
                </div>
              </div>
              <div className="contact-icons">
                <i className="fa fa-facebook"></i>
                <i className="fa fa-twitter"></i>
                <i className="fa fa-linkedin"></i>
              </div>
              <div className="copyright-area">
                © 2023 Arya Hukuk
              </div>
            </div>
            <div className="col-12 col-md-7">
              <div className="contact-map-wrapper">
                <img className="contact-map" src="../img/map.png" alt="contact-map" />

              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};
