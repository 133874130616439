import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";


export const Features = () => {
  return (
    <div id="features">
      <div className="container pb-20">
        <div className="col-12 section-title text-white text-center">
          <h2>Faaliyet Alanlarımız</h2>
        </div>
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="features-card-wrapper">
              <i className="fa fa-group"></i>
              <div>
                <h3>Sigorta Hukukundan Kaynaklı Tazminat Davaları</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="features-card-wrapper">
              <i className="fa fa-group"></i>
              <div>
                <h3>Ticaret Hukukunda Kaynaklı Alacak Davaları</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="features-card-wrapper">
              <i className="fa fa-group"></i>
              <div>
                <h3>Şirketlere Tüm Hukuki İşlemleri İçin Danışmanlık</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="features-card-wrapper">
              <i className="fa fa-group"></i>
              <div>
                <h3>İş Hukukundan Kaynaklı Alacak Davaları</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="features-card-wrapper">
              <i className="fa fa-group"></i>
              <div>
                <h3>Ticaret Hukukundan Kaynaklı Tazminat Davaları</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="features-card-wrapper">
              <i className="fa fa-group"></i>
              <div>
                <h3>Tüketici Hukukundan Kaynaklı Alacak Davaları</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="features-card-wrapper">
              <i className="fa fa-group"></i>
              <div>
                <h3>Hukukundan Kaynaklı Tazminat Davaları</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="features-card-wrapper">
              <i className="fa fa-group"></i>
              <div>
                <h3>Icra Iflas Kanunu Uyarınca Alacak Takipleri</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="features-card-wrapper">
              <i className="fa fa-group"></i>
              <div>
                <h3>KVKK kapsamında hukuki danısmanlık</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
